
import React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import './global.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {hydrate, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ErrorStart} from "./pages/routes";

const init = () => {
    const root = document.getElementById("root");
    if (!root)
        return false;



    return async () => {

        const promises:Promise<any>[]= [];

        // @ts-ignore
        if (typeof window["AbortController"] === "undefined") {
            // @ts-ignore
            promises.push(import('abortcontroller-polyfill/dist/polyfill-patch-fetch'))
        }
        if (!([].flatMap)) {
            // @ts-ignore
            promises.push(import('array-flat-polyfill'));
        }
        try {
            if (typeof window["URLSearchParams"] === "undefined" || !(new URLSearchParams().forEach)) {
                promises.push(import("./polyfill-urlsearchparam.js"))
            }
        }
        catch (e)
        {
            promises.push(import("./polyfill-urlsearchparam.js"))
        }
        try {
            if (!"".padStart)
            {
                promises.push(import("./polyfill-string"));
            }
        } catch (e) {
            promises.push(import("./polyfill-string"));
        }

        await Promise.all(promises);


        const hydra = ((window as any)["__hydrate__"])
        const qc = new QueryClient();
        if (hydra) {
            console.log("__hydrate__")
            const qcdata = (window as any).__qc__;
            if (qcdata) {
                hydrate(qc, qcdata);
            }
            const errorStart = (window as any).__errorStart__ as ErrorStart | undefined;

            hydrateRoot(document.getElementById("root")!,
                <QueryClientProvider client={qc}>
                    <BrowserRouter>
                        <App domain={new URL(document.URL).origin} errorStart={errorStart}/>
                    </BrowserRouter>
                </QueryClientProvider>);
        } else {
            const root = createRoot(document.getElementById("root")!);
            root.render(
                <QueryClientProvider client={qc}>
                    <BrowserRouter>
                        <App domain={new URL(document.URL).origin}/>
                    </BrowserRouter>
                </QueryClientProvider>);
        }
    };
};

let run = init();

if (run) {
    run();
}
else {
    let counter = 10;
    const waiting = setInterval(() => {
        run = init();
        if (run || counter <  0) {
            clearInterval(waiting);
            if (run)
               run();
            return;
        }
        counter--;
    }, 100);
}


