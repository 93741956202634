
import React, {createContext, useContext, useEffect, useMemo} from "react";
import {batch,  useDispatch, useSelector, useStore} from "react-redux";
import {PositionAction} from "./state/position";
import {AppDispatch, RootState} from "../Store";
import {PositionsAction} from "./state/positions";
import { slugifyPath } from "../../utils/slugifyPath";

type progressListener = (progress: number, duration: number) => void

const ProgressListenerContext = createContext({
    addListener: (v: progressListener) => {
        return () => {
        }
    },
    trigger: (position: number, duration: number) => {

    },
    savePosition: (position: number, playedItem: EpisodePlayedItemTime) => {

    },
    setPosition: (c: number, playedItem: EpisodePlayedItemTime) => {

    }
});

export const useProgress = () => {
    return useContext(ProgressListenerContext);
}

export const ProgressProvider = (props: {
    children: React.ReactNode
}) => {

    const playedItem = useSelector((state: RootState) => state.playedItem);
    const position = useSelector((state: RootState) => state.position);
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((appState: RootState) => appState.status);
    const store = useStore();

    const listeners = useMemo(() => {
        let listeners: progressListener[] = [];
        return {
            addListener: (l: progressListener) => {
                listeners.push(l);
                return () => {
                    listeners = listeners.filter(x => x !== l);
                }
            },
            trigger(v: number, duration: number) {
                if (!duration)
                    return;
                listeners.forEach(i => {
                    try {
                        i(v, duration);
                    } catch (e) {
                        console.error(e)
                    }
                });
            },
            setPosition: (c: number, playedItem: EpisodePlayedItemTime) => {
                const {playedItem: newPlayedItem} = store.getState() as RootState;

                if (newPlayedItem && slugifyPath(newPlayedItem, true) === slugifyPath(playedItem, true)) {
                    dispatch<PositionAction>({
                        type: "Position",
                        payload: c
                    });
                } else {
                    dispatch<PositionsAction>({
                        type: "Positions",
                        payload: {
                            time: c,
                            slug: slugifyPath(playedItem, true)
                        }
                    });
                }

            },
            savePosition: (c: number, playedItem: EpisodePlayedItemTime) => {
                batch(() => {
                    if (playedItem.duration < c)
                        c = playedItem.duration;
                    if (c < 0)
                        c = 0;

                    dispatch<PositionsAction>({
                        type: "Positions",
                        payload: {
                            time: c,
                            slug: slugifyPath(playedItem, true)
                        }
                    })
                });
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (playedItem && "programSlug" in playedItem) {
            let min = 15
            let lastPlayedItem = playedItem;
            let lastTime:number|undefined = undefined;
            const remover = listeners.addListener((c: number, d: number) => {
                if (d === 0)
                    return;
                lastTime = c;
                if (Math.abs(position - c) > min || c + 1 >= d) {
                    min += 15;
                    listeners.savePosition(c, playedItem);
                }
            });

            return () => {
                remover();
                const rootState = store.getState() as RootState;
                if (lastTime !== undefined) {
                    if ((!rootState.playedItem && lastPlayedItem)
                        || slugifyPath(lastPlayedItem, true) !== slugifyPath(rootState.playedItem!, true)
                        || rootState.status === "STOPPED") {
                        listeners.savePosition(lastTime, playedItem);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [playedItem, position, status]);

    return <ProgressListenerContext.Provider value={listeners}>
        {props.children}
    </ProgressListenerContext.Provider>
}