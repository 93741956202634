import {createContext, ReactNode, startTransition, useContext, useEffect, useMemo, useState} from "react";
import debounce from "lodash.debounce";
import {ApiClient} from "../generated/ApiClient";
import {useIsLoggedUser} from "../utils/UseIsLoggedUser";



const Ctx = createContext({
    addDbTime: (id: number, set: (c: number)=>void): (() => void) => {
        return () => {

        }
    }
});


export const usePodcastTime = (id: number) => {
    const { addDbTime } = useContext(Ctx);
    const [time, setTime] = useState(0);
    useEffect(() => addDbTime(id, setTime), [addDbTime, id]);
    return time;
}


export const PodcastTimes =  (props: {
    children: ReactNode
}) => {
    const isLogged = useIsLoggedUser();

    const [isLoggedUser, setIsLoggedUser] = useState(false);
    useEffect(() => {
        startTransition(() => setIsLoggedUser(!!isLogged));
    }, [isLogged]);


    const data = useMemo(() =>{
        return {} as { [path: string]: { time: number, setters: Array<((c:number) => void)> } };
        // eslint-disable-next-line
    }, [isLoggedUser]);

    const  findtimes = useMemo(() => debounce(() => {

        (async () => {
            const ids = Object.keys(data)
                                .filter(x => data[x].time === -1)
                                .map(x => {
                                    data[x].time = 0
                                    return x;
                                });

            try {
                const liked = await new ApiClient().getApiUserIsLikedEpisodes({
                    episodes: ids
                }, {
                    credentials: "include"
                })

                liked.forEach(x => {
                    const current = data[x.id];
                    if (current) {
                        current.time = x.listenedTime;
                    }
                })
                startTransition(() => {
                    liked.forEach(x => {
                        const current = data[x.id];
                        if (current) {
                            current.setters.forEach(x => x(current.time));
                        }
                    });
                })
            } catch (e) {

                    ids.forEach(x => {
                        const c = data[x];
                        if (c) {
                            c.time = 0;
                        }
                    })

            }
        })();
        // eslint-disable-next-line
    }, 500), [isLoggedUser]);

    const ctxValue = useMemo(() => {
        if (isLoggedUser) {
            return Object.freeze({
                addDbTime: (id:number, set: (c: number)=>void) => {

                    const specialId = `${id}`
                    if (!data[specialId]) {
                        data[specialId] = {time: -1, setters: [set]}
                        findtimes();
                    } else {
                        data[specialId].setters.push(set);
                        if (data[specialId].time > -1) {
                            const time = data[specialId].time;
                            (async () => {
                                startTransition(() => set(time));
                            })();
                        }
                    }
                    return () => {
                        const current = data[specialId];
                        if (current) {
                            current.setters = current.setters.filter(x => x !== set);
                        }
                    }
                },
                reset: (id: number, c: number) => {
                    const current = data[`${id}`];
                    if (current) {
                        current.time = c;
                        current.setters.forEach(x => x(c));
                    }
                }
            });
        } else {
            return Object.freeze({
                addDbTime: (id:number, set: (c: number)=>void) => {
                    return () => {}
                },
                reset: (id: number, c:number) => {

                }
            })
        }
        // eslint-disable-next-line
    }, [isLoggedUser]);


    return <Ctx.Provider value={ctxValue}>
        {props.children}
    </Ctx.Provider>
}


