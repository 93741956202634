/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FrontApplicationInputGdprModel,
  FrontApplicationInputInputUserLoginModel,
  FrontApplicationInputOAuthLoginModel,
  FrontApplicationInputRegisterModel,
  FrontApplicationInputRenewPasswordModel,
  FrontApplicationInputResetCodeInputModel,
  FrontApplicationInputSendResetCodeInputModel,
  FrontApplicationInputStationToggleModel,
  FrontApplicationSuccessModel,
  FrontApplicationUnsuccessModel,
  FrontApplicationUserLoggedModel,
  FrontApplicationUserModel,
  FrontWebAdvertisementsModel,
  FrontWebContentModel,
  FrontWebDetailPodcastEpisodeModel,
  FrontWebEditUserModel,
  FrontWebErrorModel,
  FrontWebHeaderModel,
  FrontWebInputLoginModel,
  FrontWebMsConnectExtendServiceToUserModel,
  FrontWebNewUserModel,
  FrontWebPodcastCategoryModel,
  FrontWebPodcastEpisodeModel,
  FrontWebPodcastProgramModel,
  FrontWebProblemInputModel,
  FrontWebRadioCategoryModel,
  FrontWebRadioDescriptionModel,
  FrontWebRadioDetailModel,
  FrontWebRadioModel,
  FrontWebRedirectInputModel,
  FrontWebRedirectRuleModel,
  FrontWebRefreshModel,
  FrontWebSearchResultCategoryRadiosModel,
  FrontWebSearchResultEpisodesModel,
  FrontWebSearchResultProgramsModel,
  FrontWebSearchResultRadiosModel,
  FrontWebSimilarRadiosModel,
  FrontWebSongLyricsModel,
  FrontWebSongModel,
  FrontWebSongProgramModel,
  FrontWebSongRadioModel,
  FrontWebSongsModel,
  FrontWebStatisticsPositionsModel,
  FrontWebTokenInputModel,
  FrontWebUserLikedModel,
  FrontWebUserLoginModel,
  FrontWebUserModel,
} from '../models/index';
import {
    FrontApplicationInputGdprModelFromJSON,
    FrontApplicationInputGdprModelToJSON,
    FrontApplicationInputInputUserLoginModelFromJSON,
    FrontApplicationInputInputUserLoginModelToJSON,
    FrontApplicationInputOAuthLoginModelFromJSON,
    FrontApplicationInputOAuthLoginModelToJSON,
    FrontApplicationInputRegisterModelFromJSON,
    FrontApplicationInputRegisterModelToJSON,
    FrontApplicationInputRenewPasswordModelFromJSON,
    FrontApplicationInputRenewPasswordModelToJSON,
    FrontApplicationInputResetCodeInputModelFromJSON,
    FrontApplicationInputResetCodeInputModelToJSON,
    FrontApplicationInputSendResetCodeInputModelFromJSON,
    FrontApplicationInputSendResetCodeInputModelToJSON,
    FrontApplicationInputStationToggleModelFromJSON,
    FrontApplicationInputStationToggleModelToJSON,
    FrontApplicationSuccessModelFromJSON,
    FrontApplicationSuccessModelToJSON,
    FrontApplicationUnsuccessModelFromJSON,
    FrontApplicationUnsuccessModelToJSON,
    FrontApplicationUserLoggedModelFromJSON,
    FrontApplicationUserLoggedModelToJSON,
    FrontApplicationUserModelFromJSON,
    FrontApplicationUserModelToJSON,
    FrontWebAdvertisementsModelFromJSON,
    FrontWebAdvertisementsModelToJSON,
    FrontWebContentModelFromJSON,
    FrontWebContentModelToJSON,
    FrontWebDetailPodcastEpisodeModelFromJSON,
    FrontWebDetailPodcastEpisodeModelToJSON,
    FrontWebEditUserModelFromJSON,
    FrontWebEditUserModelToJSON,
    FrontWebErrorModelFromJSON,
    FrontWebErrorModelToJSON,
    FrontWebHeaderModelFromJSON,
    FrontWebHeaderModelToJSON,
    FrontWebInputLoginModelFromJSON,
    FrontWebInputLoginModelToJSON,
    FrontWebMsConnectExtendServiceToUserModelFromJSON,
    FrontWebMsConnectExtendServiceToUserModelToJSON,
    FrontWebNewUserModelFromJSON,
    FrontWebNewUserModelToJSON,
    FrontWebPodcastCategoryModelFromJSON,
    FrontWebPodcastCategoryModelToJSON,
    FrontWebPodcastEpisodeModelFromJSON,
    FrontWebPodcastEpisodeModelToJSON,
    FrontWebPodcastProgramModelFromJSON,
    FrontWebPodcastProgramModelToJSON,
    FrontWebProblemInputModelFromJSON,
    FrontWebProblemInputModelToJSON,
    FrontWebRadioCategoryModelFromJSON,
    FrontWebRadioCategoryModelToJSON,
    FrontWebRadioDescriptionModelFromJSON,
    FrontWebRadioDescriptionModelToJSON,
    FrontWebRadioDetailModelFromJSON,
    FrontWebRadioDetailModelToJSON,
    FrontWebRadioModelFromJSON,
    FrontWebRadioModelToJSON,
    FrontWebRedirectInputModelFromJSON,
    FrontWebRedirectInputModelToJSON,
    FrontWebRedirectRuleModelFromJSON,
    FrontWebRedirectRuleModelToJSON,
    FrontWebRefreshModelFromJSON,
    FrontWebRefreshModelToJSON,
    FrontWebSearchResultCategoryRadiosModelFromJSON,
    FrontWebSearchResultCategoryRadiosModelToJSON,
    FrontWebSearchResultEpisodesModelFromJSON,
    FrontWebSearchResultEpisodesModelToJSON,
    FrontWebSearchResultProgramsModelFromJSON,
    FrontWebSearchResultProgramsModelToJSON,
    FrontWebSearchResultRadiosModelFromJSON,
    FrontWebSearchResultRadiosModelToJSON,
    FrontWebSimilarRadiosModelFromJSON,
    FrontWebSimilarRadiosModelToJSON,
    FrontWebSongLyricsModelFromJSON,
    FrontWebSongLyricsModelToJSON,
    FrontWebSongModelFromJSON,
    FrontWebSongModelToJSON,
    FrontWebSongProgramModelFromJSON,
    FrontWebSongProgramModelToJSON,
    FrontWebSongRadioModelFromJSON,
    FrontWebSongRadioModelToJSON,
    FrontWebSongsModelFromJSON,
    FrontWebSongsModelToJSON,
    FrontWebStatisticsPositionsModelFromJSON,
    FrontWebStatisticsPositionsModelToJSON,
    FrontWebTokenInputModelFromJSON,
    FrontWebTokenInputModelToJSON,
    FrontWebUserLikedModelFromJSON,
    FrontWebUserLikedModelToJSON,
    FrontWebUserLoginModelFromJSON,
    FrontWebUserLoginModelToJSON,
    FrontWebUserModelFromJSON,
    FrontWebUserModelToJSON,
} from '../models/index';

export interface GetApiCategoryPodcastsAllRequest {
    xNext?: string;
}

export interface GetApiCategoryRadiosAllRequest {
    xNext?: string;
}

export interface GetApiContentMarkdownRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderPodcasty1Request {
    slug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderPodcasty2Request {
    programSlug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderPodcasty3Request {
    programSlug: string;
    episodeId: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderRadioRequest {
    slug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderRadio1Request {
    slug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderRadio2Request {
    slug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderRadio3Request {
    slug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderRadio4Request {
    slug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderRadio5Request {
    slug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderRadio6Request {
    slug: string;
    programSlug: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderRadio7Request {
    slug: string;
    programSlug: string;
    episodeId: string;
}

export interface GetApiHeaderAppFrontendWebApiHeaderTexty2Request {
    slug: string;
}

export interface GetApiHeaderRadiaStylRequest {
    slug: string;
}

export interface GetApiPodcastEpisodeRequest {
    programSlug: string;
    id: number;
    xNext?: string;
}

export interface GetApiPodcastEpisodesRequest {
    programSlug: string;
    page: number;
    orderBy: GetApiPodcastEpisodesOrderByEnum;
    xNext?: string;
}

export interface GetApiPodcastProgramRequest {
    programSlug: string;
    xNext?: string;
}

export interface GetApiPodcastRecommendedProgramsRequest {
    xNext?: string;
}

export interface GetApiRadioAdvertisementsRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioAdvertisementsEpisodeRequest {
    slug: string;
    episodeId: string;
    xNext?: string;
}

export interface GetApiRadioDescriptionRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioDetailRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioPodcastNewestEpisodeRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioPodcastProgramsRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioRecommendedRequest {
    xNext?: string;
}

export interface GetApiRadioSimilarsRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioSongBackHoursRequest {
    slug: string;
    backHour: string;
}

export interface GetApiRadioSongFutureProgramRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioSongLastHourRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioSongLyricsRequest {
    slug: string;
    id: number;
}

export interface GetApiRadioSongNowRequest {
    slug: string;
    xNext?: string;
}

export interface GetApiRadioSongPingRequest {
    slug: string;
}

export interface GetApiRadioSongPingGetSeejayRequest {
    artist?: string;
    song?: string;
    onlyrefresh?: boolean;
}

export interface GetApiRedirectTranslateGetRequest {
    oldPath: string;
    xNext?: string;
}

export interface GetApiSearchEpisodeRequest {
    q: string;
    page?: number;
    orderBy?: GetApiSearchEpisodeOrderByEnum;
}

export interface GetApiSearchLastSongsRequest {
    xNext?: string;
}

export interface GetApiSearchProgramsRequest {
    q: string;
    page?: number;
}

export interface GetApiSearchProgramsByStyleRequest {
    slug: string;
    page: number;
    xNext?: string;
}

export interface GetApiSearchRadioRequest {
    q: string;
    page?: number;
}

export interface GetApiSearchRadioAlphabetRequest {
    page: number;
    xNext?: string;
}

export interface GetApiSearchRadioInCategoryRequest {
    slug: string;
    page: number;
    xNext?: string;
}

export interface GetApiSearchRadioMyRequest {
    page: number;
}

export interface GetApiSearchRadioYesterdayListenersRequest {
    page: number;
    xNext?: string;
}

export interface GetApiStreamPlaylistByDefaultRequest {
    slug: string;
}

export interface GetApiStreamPlaylistByPathRequest {
    slug: string;
    streamSlugAndQuality: string;
    streamType: string;
    type: string;
}

export interface GetApiStreamPlaylistByTypeRequest {
    slug: string;
    type: string;
}

export interface GetApiUserIsLikedEpisodesRequest {
    episodes: Array<string>;
}

export interface GetApiUserIsLikedStationsRequest {
    stations: Array<string>;
}

export interface GetOldradioPingRequest {
    slug: string;
}

export interface GetOldradioPingSeejayRequest {
    artist?: string;
    song?: string;
    onlyrefresh?: boolean;
}

export interface PatchApiUserLikeStationRequest {
    slug: string;
}

export interface PostApiProblemUndefinedRequest {
    frontWebProblemInputModel: FrontWebProblemInputModel;
}

export interface PostApiRadioSongPingPostRadiobossRequest {
    artist?: string;
    title?: string;
    cover?: string;
    password?: string;
}

export interface PostApiRadioSongPingPostRadiodjRequest {
    artist?: string;
    title?: string;
    cover?: string;
    password?: string;
}

export interface PostApiRadioSongPingPostSeejayRequest {
    artist?: string;
    song?: string;
    onlyrefresh?: boolean;
}

export interface PostApiRedirectTranslateRequest {
    frontWebRedirectInputModel: FrontWebRedirectInputModel;
}

export interface PostApiStatisticsPositionsRequest {
    frontWebStatisticsPositionsModel: FrontWebStatisticsPositionsModel;
    xNext?: string;
}

export interface PostApiUserListenedPodcastRequest {
    id: number;
    duration: number;
}

export interface PostApiUserLoginRequest {
    frontWebInputLoginModel: FrontWebInputLoginModel;
}

export interface PostApiUserRegisterRequest {
    frontWebNewUserModel: FrontWebNewUserModel;
}

export interface PostAppUserExternalLoginRequest {
    type: PostAppUserExternalLoginTypeEnum;
    frontApplicationInputOAuthLoginModel: FrontApplicationInputOAuthLoginModel;
}

export interface PostAppUserExternalLogin1Request {
    type: PostAppUserExternalLogin1TypeEnum;
    frontApplicationInputOAuthLoginModel: FrontApplicationInputOAuthLoginModel;
}

export interface PostAppUserFavouritesToogleRequest {
    frontApplicationInputStationToggleModel: FrontApplicationInputStationToggleModel;
}

export interface PostAppUserFavouritesToogle1Request {
    frontApplicationInputStationToggleModel: FrontApplicationInputStationToggleModel;
}

export interface PostAppUserGdprRequest {
    frontApplicationInputGdprModel: FrontApplicationInputGdprModel;
}

export interface PostAppUserGdpr1Request {
    frontApplicationInputGdprModel: FrontApplicationInputGdprModel;
}

export interface PostAppUserLoginRequest {
    frontApplicationInputInputUserLoginModel: FrontApplicationInputInputUserLoginModel;
}

export interface PostAppUserLogin1Request {
    frontApplicationInputInputUserLoginModel: FrontApplicationInputInputUserLoginModel;
}

export interface PostAppUserRegisterRequest {
    frontApplicationInputRegisterModel: FrontApplicationInputRegisterModel;
}

export interface PostAppUserRegister1Request {
    frontApplicationInputRegisterModel: FrontApplicationInputRegisterModel;
}

export interface PostAppUserRenewpasswordRequest {
    frontApplicationInputRenewPasswordModel: FrontApplicationInputRenewPasswordModel;
}

export interface PostAppUserRenewpassword1Request {
    frontApplicationInputRenewPasswordModel: FrontApplicationInputRenewPasswordModel;
}

export interface PostAppUserResetpasswordRequest {
    frontApplicationInputResetCodeInputModel: FrontApplicationInputResetCodeInputModel;
}

export interface PostAppUserResetpassword1Request {
    frontApplicationInputResetCodeInputModel: FrontApplicationInputResetCodeInputModel;
}

export interface PostAppUserSendresetcodeRequest {
    frontApplicationInputSendResetCodeInputModel: FrontApplicationInputSendResetCodeInputModel;
}

export interface PostAppUserSendresetcode1Request {
    frontApplicationInputSendResetCodeInputModel: FrontApplicationInputSendResetCodeInputModel;
}

export interface PostAppUserValidateRequest {
    frontApplicationInputInputUserLoginModel: FrontApplicationInputInputUserLoginModel;
}

export interface PostAppUserValidate1Request {
    frontApplicationInputInputUserLoginModel: FrontApplicationInputInputUserLoginModel;
}

export interface PostOldradioPingRadiobossRequest {
    artist?: string;
    title?: string;
    cover?: string;
    password?: string;
}

export interface PostOldradioPingSeejayRequest {
    artist?: string;
    song?: string;
    onlyrefresh?: boolean;
}

export interface PostOlduserGdprRequest {
    frontApplicationInputGdprModel: FrontApplicationInputGdprModel;
}

export interface PutApiUserConnectFacebookRequest {
    frontWebTokenInputModel: FrontWebTokenInputModel;
}

export interface PutApiUserConnectGoogleRequest {
    frontWebTokenInputModel: FrontWebTokenInputModel;
}

export interface PutApiUserConnectMsRequest {
    frontWebMsConnectExtendServiceToUserModel: FrontWebMsConnectExtendServiceToUserModel;
}

export interface PutApiUserEditRequest {
    frontWebEditUserModel: FrontWebEditUserModel;
}

export interface PutApiUserRefreshRequest {
    frontWebRefreshModel: FrontWebRefreshModel;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async deleteApiUserDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteApiUserDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteApiUserDeleteRaw(initOverrides);
    }

    /**
     */
    async deleteAppUserDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/delete.json`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async deleteAppUserDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.deleteAppUserDeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAppUserDelete1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/delete.json`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async deleteAppUserDelete1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.deleteAppUserDelete1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiCategoryPodcastsAllRaw(requestParameters: GetApiCategoryPodcastsAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FrontWebPodcastCategoryModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/categories/podcasts.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontWebPodcastCategoryModelFromJSON));
    }

    /**
     */
    async getApiCategoryPodcastsAll(requestParameters: GetApiCategoryPodcastsAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FrontWebPodcastCategoryModel>> {
        const response = await this.getApiCategoryPodcastsAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiCategoryRadiosAllRaw(requestParameters: GetApiCategoryRadiosAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FrontWebRadioCategoryModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/categories/radios.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontWebRadioCategoryModelFromJSON));
    }

    /**
     */
    async getApiCategoryRadiosAll(requestParameters: GetApiCategoryRadiosAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FrontWebRadioCategoryModel>> {
        const response = await this.getApiCategoryRadiosAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiContentMarkdownRaw(requestParameters: GetApiContentMarkdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebContentModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiContentMarkdown.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/content/{slug}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebContentModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiContentMarkdown(requestParameters: GetApiContentMarkdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebContentModel> {
        const response = await this.getApiContentMarkdownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAboutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/index.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAbout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAboutRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderPodcastyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/podcasty.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderPodcasty(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderPodcastyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderPodcasty1Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderPodcasty1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderPodcasty1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/podcasty/styl/{slug}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderPodcasty1(requestParameters: GetApiHeaderAppFrontendWebApiHeaderPodcasty1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderPodcasty1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderPodcasty2Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderPodcasty2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.programSlug === null || requestParameters.programSlug === undefined) {
            throw new runtime.RequiredError('programSlug','Required parameter requestParameters.programSlug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderPodcasty2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/podcasty/{programSlug}.json`.replace(`{${"programSlug"}}`, encodeURIComponent(String(requestParameters.programSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderPodcasty2(requestParameters: GetApiHeaderAppFrontendWebApiHeaderPodcasty2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderPodcasty2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderPodcasty3Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderPodcasty3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.programSlug === null || requestParameters.programSlug === undefined) {
            throw new runtime.RequiredError('programSlug','Required parameter requestParameters.programSlug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderPodcasty3.');
        }

        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderPodcasty3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/podcasty/{programSlug}/{episodeId}.json`.replace(`{${"programSlug"}}`, encodeURIComponent(String(requestParameters.programSlug))).replace(`{${"episodeId"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderPodcasty3(requestParameters: GetApiHeaderAppFrontendWebApiHeaderPodcasty3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderPodcasty3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadioRaw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/{slug}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderRadioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio1Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/{slug}/info.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio1(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderRadio1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio2Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/{slug}/playlist.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio2(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderRadio2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio3Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/{slug}/podobna-radia.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio3(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderRadio3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio4Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio4.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/{slug}/program.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio4(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderRadio4Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio5Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio5.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/{slug}/podcasty.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio5(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderRadio5Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio6Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio6Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio6.');
        }

        if (requestParameters.programSlug === null || requestParameters.programSlug === undefined) {
            throw new runtime.RequiredError('programSlug','Required parameter requestParameters.programSlug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio6.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/{slug}/podcasty/{programSlug}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"programSlug"}}`, encodeURIComponent(String(requestParameters.programSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio6(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio6Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderRadio6Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio7Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio7Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio7.');
        }

        if (requestParameters.programSlug === null || requestParameters.programSlug === undefined) {
            throw new runtime.RequiredError('programSlug','Required parameter requestParameters.programSlug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio7.');
        }

        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderRadio7.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/{slug}/podcasty/{programSlug}/{episodeId}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"programSlug"}}`, encodeURIComponent(String(requestParameters.programSlug))).replace(`{${"episodeId"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderRadio7(requestParameters: GetApiHeaderAppFrontendWebApiHeaderRadio7Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderRadio7Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderTextyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/jak-nas-naladite.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderTexty(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderTextyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderTexty1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/stahnout-aplikaci.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderTexty1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderTexty1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderTexty2Raw(requestParameters: GetApiHeaderAppFrontendWebApiHeaderTexty2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderAppFrontendWebApiHeaderTexty2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/{slug}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderTexty2(requestParameters: GetApiHeaderAppFrontendWebApiHeaderTexty2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderTexty2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderUcetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/zapomenute-heslo.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderUcet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderUcetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderUcet1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/muj-ucet.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderUcet1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderUcet1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderUcet2Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/registrace.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderAppFrontendWebApiHeaderUcet2(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderAppFrontendWebApiHeaderUcet2Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderHledaniRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/hledani.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderHledani(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderHledaniRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderHledaniEpizodyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/hledani/epizody.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderHledaniEpizody(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderHledaniEpizodyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderHledaniPodcastyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/hledani/podcasty.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderHledaniPodcasty(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderHledaniPodcastyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderHledaniRadiaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/hledani/radia.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderHledaniRadia(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderHledaniRadiaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderRadiaAbecedneRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/abecedne.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderRadiaAbecedne(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderRadiaAbecedneRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderRadiaMojeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/moje.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderRadiaMoje(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderRadiaMojeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderRadiaPoslechovostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderRadiaPoslechovost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderRadiaPoslechovostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderRadiaStylRaw(requestParameters: GetApiHeaderRadiaStylRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiHeaderRadiaStyl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/styl/{slug}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderRadiaStyl(requestParameters: GetApiHeaderRadiaStylRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderRadiaStylRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiHeaderRadiaStylyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebHeaderModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/header/radia/styl.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebHeaderModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiHeaderRadiaStyly(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebHeaderModel> {
        const response = await this.getApiHeaderRadiaStylyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiPodcastEpisodeRaw(requestParameters: GetApiPodcastEpisodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebDetailPodcastEpisodeModel>> {
        if (requestParameters.programSlug === null || requestParameters.programSlug === undefined) {
            throw new runtime.RequiredError('programSlug','Required parameter requestParameters.programSlug was null or undefined when calling getApiPodcastEpisode.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getApiPodcastEpisode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/program/{programSlug}/episodes/{id}.json`.replace(`{${"programSlug"}}`, encodeURIComponent(String(requestParameters.programSlug))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebDetailPodcastEpisodeModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiPodcastEpisode(requestParameters: GetApiPodcastEpisodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebDetailPodcastEpisodeModel> {
        const response = await this.getApiPodcastEpisodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiPodcastEpisodesRaw(requestParameters: GetApiPodcastEpisodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultEpisodesModel>> {
        if (requestParameters.programSlug === null || requestParameters.programSlug === undefined) {
            throw new runtime.RequiredError('programSlug','Required parameter requestParameters.programSlug was null or undefined when calling getApiPodcastEpisodes.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getApiPodcastEpisodes.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling getApiPodcastEpisodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/program/{programSlug}/episodes/{page}.{orderBy}.json`.replace(`{${"programSlug"}}`, encodeURIComponent(String(requestParameters.programSlug))).replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))).replace(`{${"orderBy"}}`, encodeURIComponent(String(requestParameters.orderBy))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultEpisodesModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiPodcastEpisodes(requestParameters: GetApiPodcastEpisodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultEpisodesModel> {
        const response = await this.getApiPodcastEpisodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiPodcastProgramRaw(requestParameters: GetApiPodcastProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebPodcastProgramModel>> {
        if (requestParameters.programSlug === null || requestParameters.programSlug === undefined) {
            throw new runtime.RequiredError('programSlug','Required parameter requestParameters.programSlug was null or undefined when calling getApiPodcastProgram.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/program/{programSlug}.json`.replace(`{${"programSlug"}}`, encodeURIComponent(String(requestParameters.programSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebPodcastProgramModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiPodcastProgram(requestParameters: GetApiPodcastProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebPodcastProgramModel> {
        const response = await this.getApiPodcastProgramRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiPodcastRecommendedProgramsRaw(requestParameters: GetApiPodcastRecommendedProgramsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultProgramsModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/program/recommended.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultProgramsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiPodcastRecommendedPrograms(requestParameters: GetApiPodcastRecommendedProgramsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultProgramsModel> {
        const response = await this.getApiPodcastRecommendedProgramsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioAdvertisementsRaw(requestParameters: GetApiRadioAdvertisementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebAdvertisementsModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioAdvertisements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/advertisements.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebAdvertisementsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioAdvertisements(requestParameters: GetApiRadioAdvertisementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebAdvertisementsModel> {
        const response = await this.getApiRadioAdvertisementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioAdvertisementsEpisodeRaw(requestParameters: GetApiRadioAdvertisementsEpisodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebAdvertisementsModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioAdvertisementsEpisode.');
        }

        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling getApiRadioAdvertisementsEpisode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/advertisements.e{episodeId}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"episodeId"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebAdvertisementsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioAdvertisementsEpisode(requestParameters: GetApiRadioAdvertisementsEpisodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebAdvertisementsModel> {
        const response = await this.getApiRadioAdvertisementsEpisodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioDescriptionRaw(requestParameters: GetApiRadioDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebRadioDescriptionModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/description.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebRadioDescriptionModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioDescription(requestParameters: GetApiRadioDescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebRadioDescriptionModel> {
        const response = await this.getApiRadioDescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioDetailRaw(requestParameters: GetApiRadioDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebRadioDetailModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebRadioDetailModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioDetail(requestParameters: GetApiRadioDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebRadioDetailModel> {
        const response = await this.getApiRadioDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioPodcastNewestEpisodeRaw(requestParameters: GetApiRadioPodcastNewestEpisodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebPodcastEpisodeModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioPodcastNewestEpisode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/episodes/newest.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebPodcastEpisodeModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioPodcastNewestEpisode(requestParameters: GetApiRadioPodcastNewestEpisodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebPodcastEpisodeModel> {
        const response = await this.getApiRadioPodcastNewestEpisodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioPodcastProgramsRaw(requestParameters: GetApiRadioPodcastProgramsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultProgramsModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioPodcastPrograms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/programs.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultProgramsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioPodcastPrograms(requestParameters: GetApiRadioPodcastProgramsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultProgramsModel> {
        const response = await this.getApiRadioPodcastProgramsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioRecommendedRaw(requestParameters: GetApiRadioRecommendedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FrontWebRadioModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/recommeded.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontWebRadioModelFromJSON));
    }

    /**
     */
    async getApiRadioRecommended(requestParameters: GetApiRadioRecommendedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FrontWebRadioModel>> {
        const response = await this.getApiRadioRecommendedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioSimilarsRaw(requestParameters: GetApiRadioSimilarsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSimilarRadiosModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioSimilars.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/similars.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSimilarRadiosModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioSimilars(requestParameters: GetApiRadioSimilarsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSimilarRadiosModel> {
        const response = await this.getApiRadioSimilarsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioSongBackHoursRaw(requestParameters: GetApiRadioSongBackHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSongsModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioSongBackHours.');
        }

        if (requestParameters.backHour === null || requestParameters.backHour === undefined) {
            throw new runtime.RequiredError('backHour','Required parameter requestParameters.backHour was null or undefined when calling getApiRadioSongBackHours.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/songs/hours/{backHour}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"backHour"}}`, encodeURIComponent(String(requestParameters.backHour))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSongsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioSongBackHours(requestParameters: GetApiRadioSongBackHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSongsModel> {
        const response = await this.getApiRadioSongBackHoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioSongFutureProgramRaw(requestParameters: GetApiRadioSongFutureProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FrontWebSongProgramModel>>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioSongFutureProgram.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/songs/program.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontWebSongProgramModelFromJSON));
    }

    /**
     */
    async getApiRadioSongFutureProgram(requestParameters: GetApiRadioSongFutureProgramRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FrontWebSongProgramModel>> {
        const response = await this.getApiRadioSongFutureProgramRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioSongLastHourRaw(requestParameters: GetApiRadioSongLastHourRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSongsModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioSongLastHour.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/songs/hours/lasthour.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSongsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioSongLastHour(requestParameters: GetApiRadioSongLastHourRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSongsModel> {
        const response = await this.getApiRadioSongLastHourRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioSongLyricsRaw(requestParameters: GetApiRadioSongLyricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSongLyricsModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioSongLyrics.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getApiRadioSongLyrics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/songs/lyrics/{id}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSongLyricsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioSongLyrics(requestParameters: GetApiRadioSongLyricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSongLyricsModel> {
        const response = await this.getApiRadioSongLyricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioSongNowRaw(requestParameters: GetApiRadioSongNowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSongModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioSongNow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/songs/now.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSongModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiRadioSongNow(requestParameters: GetApiRadioSongNowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSongModel> {
        const response = await this.getApiRadioSongNowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRadioSongPingRaw(requestParameters: GetApiRadioSongPingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiRadioSongPing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/songs/ping`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getApiRadioSongPing(requestParameters: GetApiRadioSongPingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getApiRadioSongPingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getApiRadioSongPingGetSeejayRaw(requestParameters: GetApiRadioSongPingGetSeejayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.artist !== undefined) {
            queryParameters['artist'] = requestParameters.artist;
        }

        if (requestParameters.song !== undefined) {
            queryParameters['song'] = requestParameters.song;
        }

        if (requestParameters.onlyrefresh !== undefined) {
            queryParameters['onlyrefresh'] = requestParameters.onlyrefresh;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/songs/seejaay`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getApiRadioSongPingGetSeejay(requestParameters: GetApiRadioSongPingGetSeejayRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getApiRadioSongPingGetSeejayRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getApiRedirectRulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FrontWebRedirectRuleModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/redirect/rules.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontWebRedirectRuleModelFromJSON));
    }

    /**
     */
    async getApiRedirectRules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FrontWebRedirectRuleModel>> {
        const response = await this.getApiRedirectRulesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiRedirectTranslateGetRaw(requestParameters: GetApiRedirectTranslateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.oldPath === null || requestParameters.oldPath === undefined) {
            throw new runtime.RequiredError('oldPath','Required parameter requestParameters.oldPath was null or undefined when calling getApiRedirectTranslateGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.oldPath !== undefined) {
            queryParameters['oldPath'] = requestParameters.oldPath;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getApiRedirectTranslateGet(requestParameters: GetApiRedirectTranslateGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getApiRedirectTranslateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchEpisodeRaw(requestParameters: GetApiSearchEpisodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultEpisodesModel>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling getApiSearchEpisode.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/episodes.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultEpisodesModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiSearchEpisode(requestParameters: GetApiSearchEpisodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultEpisodesModel> {
        const response = await this.getApiSearchEpisodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchLastSongsRaw(requestParameters: GetApiSearchLastSongsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FrontWebSongRadioModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/songs.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontWebSongRadioModelFromJSON));
    }

    /**
     */
    async getApiSearchLastSongs(requestParameters: GetApiSearchLastSongsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FrontWebSongRadioModel>> {
        const response = await this.getApiSearchLastSongsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchProgramsRaw(requestParameters: GetApiSearchProgramsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultProgramsModel>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling getApiSearchPrograms.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/programs.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultProgramsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiSearchPrograms(requestParameters: GetApiSearchProgramsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultProgramsModel> {
        const response = await this.getApiSearchProgramsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchProgramsByStyleRaw(requestParameters: GetApiSearchProgramsByStyleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultProgramsModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiSearchProgramsByStyle.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getApiSearchProgramsByStyle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/programs/byStyle/{slug}.{page}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultProgramsModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiSearchProgramsByStyle(requestParameters: GetApiSearchProgramsByStyleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultProgramsModel> {
        const response = await this.getApiSearchProgramsByStyleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchRadioRaw(requestParameters: GetApiSearchRadioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultRadiosModel>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling getApiSearchRadio.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/radio.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultRadiosModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiSearchRadio(requestParameters: GetApiSearchRadioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultRadiosModel> {
        const response = await this.getApiSearchRadioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchRadioAlphabetRaw(requestParameters: GetApiSearchRadioAlphabetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultRadiosModel>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getApiSearchRadioAlphabet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/radio/byAlphabet.{page}.json`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultRadiosModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiSearchRadioAlphabet(requestParameters: GetApiSearchRadioAlphabetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultRadiosModel> {
        const response = await this.getApiSearchRadioAlphabetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchRadioInCategoryRaw(requestParameters: GetApiSearchRadioInCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultCategoryRadiosModel>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiSearchRadioInCategory.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getApiSearchRadioInCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/radio/byCategory/{slug}.{page}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultCategoryRadiosModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiSearchRadioInCategory(requestParameters: GetApiSearchRadioInCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultCategoryRadiosModel> {
        const response = await this.getApiSearchRadioInCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchRadioMyRaw(requestParameters: GetApiSearchRadioMyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultRadiosModel>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getApiSearchRadioMy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/radio/byMy.{page}.json`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultRadiosModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiSearchRadioMy(requestParameters: GetApiSearchRadioMyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultRadiosModel> {
        const response = await this.getApiSearchRadioMyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiSearchRadioYesterdayListenersRaw(requestParameters: GetApiSearchRadioYesterdayListenersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebSearchResultRadiosModel>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getApiSearchRadioYesterdayListeners.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/search/radio/byYesterdayListeners.{page}.json`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebSearchResultRadiosModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiSearchRadioYesterdayListeners(requestParameters: GetApiSearchRadioYesterdayListenersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebSearchResultRadiosModel> {
        const response = await this.getApiSearchRadioYesterdayListenersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiStreamPlaylistByDefaultRaw(requestParameters: GetApiStreamPlaylistByDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiStreamPlaylistByDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/streams.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getApiStreamPlaylistByDefault(requestParameters: GetApiStreamPlaylistByDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getApiStreamPlaylistByDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiStreamPlaylistByPathRaw(requestParameters: GetApiStreamPlaylistByPathRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiStreamPlaylistByPath.');
        }

        if (requestParameters.streamSlugAndQuality === null || requestParameters.streamSlugAndQuality === undefined) {
            throw new runtime.RequiredError('streamSlugAndQuality','Required parameter requestParameters.streamSlugAndQuality was null or undefined when calling getApiStreamPlaylistByPath.');
        }

        if (requestParameters.streamType === null || requestParameters.streamType === undefined) {
            throw new runtime.RequiredError('streamType','Required parameter requestParameters.streamType was null or undefined when calling getApiStreamPlaylistByPath.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getApiStreamPlaylistByPath.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/streams/{streamSlugAndQuality}.{streamType}.{type}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"streamSlugAndQuality"}}`, encodeURIComponent(String(requestParameters.streamSlugAndQuality))).replace(`{${"streamType"}}`, encodeURIComponent(String(requestParameters.streamType))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getApiStreamPlaylistByPath(requestParameters: GetApiStreamPlaylistByPathRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getApiStreamPlaylistByPathRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiStreamPlaylistByTypeRaw(requestParameters: GetApiStreamPlaylistByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getApiStreamPlaylistByType.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getApiStreamPlaylistByType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/{slug}/streams.{type}.json`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getApiStreamPlaylistByType(requestParameters: GetApiStreamPlaylistByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getApiStreamPlaylistByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiTestTest1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/test1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getApiTestTest1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getApiTestTest1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiTestTest2Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/test2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getApiTestTest2(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getApiTestTest2Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiUserCurrentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebUserModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebUserModelFromJSON(jsonValue));
    }

    /**
     */
    async getApiUserCurrent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebUserModel> {
        const response = await this.getApiUserCurrentRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiUserIsLikedEpisodesRaw(requestParameters: GetApiUserIsLikedEpisodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FrontWebUserLikedModel>>> {
        if (requestParameters.episodes === null || requestParameters.episodes === undefined) {
            throw new runtime.RequiredError('episodes','Required parameter requestParameters.episodes was null or undefined when calling getApiUserIsLikedEpisodes.');
        }

        const queryParameters: any = {};

        if (requestParameters.episodes) {
            queryParameters['episodes[]'] = requestParameters.episodes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/liked/episodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontWebUserLikedModelFromJSON));
    }

    /**
     */
    async getApiUserIsLikedEpisodes(requestParameters: GetApiUserIsLikedEpisodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FrontWebUserLikedModel>> {
        const response = await this.getApiUserIsLikedEpisodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiUserIsLikedStationsRaw(requestParameters: GetApiUserIsLikedStationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FrontWebUserLikedModel>>> {
        if (requestParameters.stations === null || requestParameters.stations === undefined) {
            throw new runtime.RequiredError('stations','Required parameter requestParameters.stations was null or undefined when calling getApiUserIsLikedStations.');
        }

        const queryParameters: any = {};

        if (requestParameters.stations) {
            queryParameters['stations[]'] = requestParameters.stations;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/liked/stations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrontWebUserLikedModelFromJSON));
    }

    /**
     */
    async getApiUserIsLikedStations(requestParameters: GetApiUserIsLikedStationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FrontWebUserLikedModel>> {
        const response = await this.getApiUserIsLikedStationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiUserLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getApiUserLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getApiUserLogoutRaw(initOverrides);
    }

    /**
     */
    async getAppUserAboutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/about.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserModelFromJSON(jsonValue));
    }

    /**
     */
    async getAppUserAbout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserModel> {
        const response = await this.getAppUserAboutRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAppUserAbout1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/about.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserModelFromJSON(jsonValue));
    }

    /**
     */
    async getAppUserAbout1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserModel> {
        const response = await this.getAppUserAbout1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAppUserFavouritesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/favourites/all.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAppUserFavourites(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAppUserFavouritesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAppUserFavourites1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/favourites/all.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAppUserFavourites1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAppUserFavourites1Raw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getOldradioPingRaw(requestParameters: GetOldradioPingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getOldradioPing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{slug}/ping`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getOldradioPing(requestParameters: GetOldradioPingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getOldradioPingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getOldradioPingSeejayRaw(requestParameters: GetOldradioPingSeejayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.artist !== undefined) {
            queryParameters['artist'] = requestParameters.artist;
        }

        if (requestParameters.song !== undefined) {
            queryParameters['song'] = requestParameters.song;
        }

        if (requestParameters.onlyrefresh !== undefined) {
            queryParameters['onlyrefresh'] = requestParameters.onlyrefresh;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/add-to-playlist/seejay`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getOldradioPingSeejay(requestParameters: GetOldradioPingSeejayRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getOldradioPingSeejayRaw(requestParameters, initOverrides);
    }

    /**
     */
    async patchApiUserLikeStationRaw(requestParameters: PatchApiUserLikeStationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling patchApiUserLikeStation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/liked/stations/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async patchApiUserLikeStation(requestParameters: PatchApiUserLikeStationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchApiUserLikeStationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postApiProblemUndefinedRaw(requestParameters: PostApiProblemUndefinedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontWebProblemInputModel === null || requestParameters.frontWebProblemInputModel === undefined) {
            throw new runtime.RequiredError('frontWebProblemInputModel','Required parameter requestParameters.frontWebProblemInputModel was null or undefined when calling postApiProblemUndefined.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/problem/undefined`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebProblemInputModelToJSON(requestParameters.frontWebProblemInputModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postApiProblemUndefined(requestParameters: PostApiProblemUndefinedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postApiProblemUndefinedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postApiRadioSongPingPostRadiobossRaw(requestParameters: PostApiRadioSongPingPostRadiobossRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.artist !== undefined) {
            formParams.append('artist', requestParameters.artist as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.cover !== undefined) {
            formParams.append('cover', requestParameters.cover as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        const response = await this.request({
            path: `/api/v1/radio/songs/radioboss`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postApiRadioSongPingPostRadioboss(requestParameters: PostApiRadioSongPingPostRadiobossRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postApiRadioSongPingPostRadiobossRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postApiRadioSongPingPostRadiodjRaw(requestParameters: PostApiRadioSongPingPostRadiodjRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.artist !== undefined) {
            formParams.append('artist', requestParameters.artist as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.cover !== undefined) {
            formParams.append('cover', requestParameters.cover as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        const response = await this.request({
            path: `/api/v1/radio/songs/radiodj`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postApiRadioSongPingPostRadiodj(requestParameters: PostApiRadioSongPingPostRadiodjRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postApiRadioSongPingPostRadiodjRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postApiRadioSongPingPostSeejayRaw(requestParameters: PostApiRadioSongPingPostSeejayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.artist !== undefined) {
            queryParameters['artist'] = requestParameters.artist;
        }

        if (requestParameters.song !== undefined) {
            queryParameters['song'] = requestParameters.song;
        }

        if (requestParameters.onlyrefresh !== undefined) {
            queryParameters['onlyrefresh'] = requestParameters.onlyrefresh;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/radio/songs/seejaay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postApiRadioSongPingPostSeejay(requestParameters: PostApiRadioSongPingPostSeejayRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postApiRadioSongPingPostSeejayRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postApiRedirectTranslateRaw(requestParameters: PostApiRedirectTranslateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.frontWebRedirectInputModel === null || requestParameters.frontWebRedirectInputModel === undefined) {
            throw new runtime.RequiredError('frontWebRedirectInputModel','Required parameter requestParameters.frontWebRedirectInputModel was null or undefined when calling postApiRedirectTranslate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/redirect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebRedirectInputModelToJSON(requestParameters.frontWebRedirectInputModel),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async postApiRedirectTranslate(requestParameters: PostApiRedirectTranslateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.postApiRedirectTranslateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postApiStatisticsPositionsRaw(requestParameters: PostApiStatisticsPositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontWebStatisticsPositionsModel === null || requestParameters.frontWebStatisticsPositionsModel === undefined) {
            throw new runtime.RequiredError('frontWebStatisticsPositionsModel','Required parameter requestParameters.frontWebStatisticsPositionsModel was null or undefined when calling postApiStatisticsPositions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xNext !== undefined && requestParameters.xNext !== null) {
            headerParameters['x-next'] = String(requestParameters.xNext);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/statistics/positions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebStatisticsPositionsModelToJSON(requestParameters.frontWebStatisticsPositionsModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postApiStatisticsPositions(requestParameters: PostApiStatisticsPositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postApiStatisticsPositionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postApiUserListenedPodcastRaw(requestParameters: PostApiUserListenedPodcastRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postApiUserListenedPodcast.');
        }

        if (requestParameters.duration === null || requestParameters.duration === undefined) {
            throw new runtime.RequiredError('duration','Required parameter requestParameters.duration was null or undefined when calling postApiUserListenedPodcast.');
        }

        const queryParameters: any = {};

        if (requestParameters.duration !== undefined) {
            queryParameters['duration'] = requestParameters.duration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/liked/episodes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postApiUserListenedPodcast(requestParameters: PostApiUserListenedPodcastRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postApiUserListenedPodcastRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postApiUserLoginRaw(requestParameters: PostApiUserLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebUserLoginModel>> {
        if (requestParameters.frontWebInputLoginModel === null || requestParameters.frontWebInputLoginModel === undefined) {
            throw new runtime.RequiredError('frontWebInputLoginModel','Required parameter requestParameters.frontWebInputLoginModel was null or undefined when calling postApiUserLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebInputLoginModelToJSON(requestParameters.frontWebInputLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebUserLoginModelFromJSON(jsonValue));
    }

    /**
     */
    async postApiUserLogin(requestParameters: PostApiUserLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebUserLoginModel> {
        const response = await this.postApiUserLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postApiUserRegisterRaw(requestParameters: PostApiUserRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontWebNewUserModel === null || requestParameters.frontWebNewUserModel === undefined) {
            throw new runtime.RequiredError('frontWebNewUserModel','Required parameter requestParameters.frontWebNewUserModel was null or undefined when calling postApiUserRegister.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebNewUserModelToJSON(requestParameters.frontWebNewUserModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postApiUserRegister(requestParameters: PostApiUserRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postApiUserRegisterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postAppUserExternalLoginRaw(requestParameters: PostAppUserExternalLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling postAppUserExternalLogin.');
        }

        if (requestParameters.frontApplicationInputOAuthLoginModel === null || requestParameters.frontApplicationInputOAuthLoginModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputOAuthLoginModel','Required parameter requestParameters.frontApplicationInputOAuthLoginModel was null or undefined when calling postAppUserExternalLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{type}.json`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputOAuthLoginModelToJSON(requestParameters.frontApplicationInputOAuthLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserExternalLogin(requestParameters: PostAppUserExternalLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserExternalLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserExternalLogin1Raw(requestParameters: PostAppUserExternalLogin1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling postAppUserExternalLogin1.');
        }

        if (requestParameters.frontApplicationInputOAuthLoginModel === null || requestParameters.frontApplicationInputOAuthLoginModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputOAuthLoginModel','Required parameter requestParameters.frontApplicationInputOAuthLoginModel was null or undefined when calling postAppUserExternalLogin1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/{type}.json`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputOAuthLoginModelToJSON(requestParameters.frontApplicationInputOAuthLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserExternalLogin1(requestParameters: PostAppUserExternalLogin1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserExternalLogin1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserFavouritesToogleRaw(requestParameters: PostAppUserFavouritesToogleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputStationToggleModel === null || requestParameters.frontApplicationInputStationToggleModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputStationToggleModel','Required parameter requestParameters.frontApplicationInputStationToggleModel was null or undefined when calling postAppUserFavouritesToogle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/favourites/toggle.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputStationToggleModelToJSON(requestParameters.frontApplicationInputStationToggleModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserFavouritesToogle(requestParameters: PostAppUserFavouritesToogleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postAppUserFavouritesToogleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserFavouritesToogle1Raw(requestParameters: PostAppUserFavouritesToogle1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputStationToggleModel === null || requestParameters.frontApplicationInputStationToggleModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputStationToggleModel','Required parameter requestParameters.frontApplicationInputStationToggleModel was null or undefined when calling postAppUserFavouritesToogle1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/favourites/toggle.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputStationToggleModelToJSON(requestParameters.frontApplicationInputStationToggleModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserFavouritesToogle1(requestParameters: PostAppUserFavouritesToogle1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postAppUserFavouritesToogle1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserGdprRaw(requestParameters: PostAppUserGdprRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputGdprModel === null || requestParameters.frontApplicationInputGdprModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputGdprModel','Required parameter requestParameters.frontApplicationInputGdprModel was null or undefined when calling postAppUserGdpr.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/gdpr.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputGdprModelToJSON(requestParameters.frontApplicationInputGdprModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserGdpr(requestParameters: PostAppUserGdprRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postAppUserGdprRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserGdpr1Raw(requestParameters: PostAppUserGdpr1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputGdprModel === null || requestParameters.frontApplicationInputGdprModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputGdprModel','Required parameter requestParameters.frontApplicationInputGdprModel was null or undefined when calling postAppUserGdpr1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/gdpr.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputGdprModelToJSON(requestParameters.frontApplicationInputGdprModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserGdpr1(requestParameters: PostAppUserGdpr1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postAppUserGdpr1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserLoginRaw(requestParameters: PostAppUserLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.frontApplicationInputInputUserLoginModel === null || requestParameters.frontApplicationInputInputUserLoginModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputInputUserLoginModel','Required parameter requestParameters.frontApplicationInputInputUserLoginModel was null or undefined when calling postAppUserLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/login.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputInputUserLoginModelToJSON(requestParameters.frontApplicationInputInputUserLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserLogin(requestParameters: PostAppUserLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserLogin1Raw(requestParameters: PostAppUserLogin1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.frontApplicationInputInputUserLoginModel === null || requestParameters.frontApplicationInputInputUserLoginModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputInputUserLoginModel','Required parameter requestParameters.frontApplicationInputInputUserLoginModel was null or undefined when calling postAppUserLogin1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/login.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputInputUserLoginModelToJSON(requestParameters.frontApplicationInputInputUserLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserLogin1(requestParameters: PostAppUserLogin1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserLogin1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserRegisterRaw(requestParameters: PostAppUserRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputRegisterModel === null || requestParameters.frontApplicationInputRegisterModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputRegisterModel','Required parameter requestParameters.frontApplicationInputRegisterModel was null or undefined when calling postAppUserRegister.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/register.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputRegisterModelToJSON(requestParameters.frontApplicationInputRegisterModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserRegister(requestParameters: PostAppUserRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postAppUserRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserRegister1Raw(requestParameters: PostAppUserRegister1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputRegisterModel === null || requestParameters.frontApplicationInputRegisterModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputRegisterModel','Required parameter requestParameters.frontApplicationInputRegisterModel was null or undefined when calling postAppUserRegister1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/register.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputRegisterModelToJSON(requestParameters.frontApplicationInputRegisterModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserRegister1(requestParameters: PostAppUserRegister1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postAppUserRegister1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserRenewpasswordRaw(requestParameters: PostAppUserRenewpasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.frontApplicationInputRenewPasswordModel === null || requestParameters.frontApplicationInputRenewPasswordModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputRenewPasswordModel','Required parameter requestParameters.frontApplicationInputRenewPasswordModel was null or undefined when calling postAppUserRenewpassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/renewpassword.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputRenewPasswordModelToJSON(requestParameters.frontApplicationInputRenewPasswordModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserRenewpassword(requestParameters: PostAppUserRenewpasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserRenewpasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserRenewpassword1Raw(requestParameters: PostAppUserRenewpassword1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.frontApplicationInputRenewPasswordModel === null || requestParameters.frontApplicationInputRenewPasswordModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputRenewPasswordModel','Required parameter requestParameters.frontApplicationInputRenewPasswordModel was null or undefined when calling postAppUserRenewpassword1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/renewpassword.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputRenewPasswordModelToJSON(requestParameters.frontApplicationInputRenewPasswordModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserRenewpassword1(requestParameters: PostAppUserRenewpassword1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserRenewpassword1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserResetpasswordRaw(requestParameters: PostAppUserResetpasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.frontApplicationInputResetCodeInputModel === null || requestParameters.frontApplicationInputResetCodeInputModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputResetCodeInputModel','Required parameter requestParameters.frontApplicationInputResetCodeInputModel was null or undefined when calling postAppUserResetpassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/resetPassword.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputResetCodeInputModelToJSON(requestParameters.frontApplicationInputResetCodeInputModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserResetpassword(requestParameters: PostAppUserResetpasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserResetpasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserResetpassword1Raw(requestParameters: PostAppUserResetpassword1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.frontApplicationInputResetCodeInputModel === null || requestParameters.frontApplicationInputResetCodeInputModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputResetCodeInputModel','Required parameter requestParameters.frontApplicationInputResetCodeInputModel was null or undefined when calling postAppUserResetpassword1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/resetPassword.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputResetCodeInputModelToJSON(requestParameters.frontApplicationInputResetCodeInputModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserResetpassword1(requestParameters: PostAppUserResetpassword1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserResetpassword1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserSendresetcodeRaw(requestParameters: PostAppUserSendresetcodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputSendResetCodeInputModel === null || requestParameters.frontApplicationInputSendResetCodeInputModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputSendResetCodeInputModel','Required parameter requestParameters.frontApplicationInputSendResetCodeInputModel was null or undefined when calling postAppUserSendresetcode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/sendResetCode.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputSendResetCodeInputModelToJSON(requestParameters.frontApplicationInputSendResetCodeInputModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserSendresetcode(requestParameters: PostAppUserSendresetcodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postAppUserSendresetcodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserSendresetcode1Raw(requestParameters: PostAppUserSendresetcode1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputSendResetCodeInputModel === null || requestParameters.frontApplicationInputSendResetCodeInputModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputSendResetCodeInputModel','Required parameter requestParameters.frontApplicationInputSendResetCodeInputModel was null or undefined when calling postAppUserSendresetcode1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/sendResetCode.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputSendResetCodeInputModelToJSON(requestParameters.frontApplicationInputSendResetCodeInputModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserSendresetcode1(requestParameters: PostAppUserSendresetcode1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postAppUserSendresetcode1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserValidateRaw(requestParameters: PostAppUserValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.frontApplicationInputInputUserLoginModel === null || requestParameters.frontApplicationInputInputUserLoginModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputInputUserLoginModel','Required parameter requestParameters.frontApplicationInputInputUserLoginModel was null or undefined when calling postAppUserValidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/validate.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputInputUserLoginModelToJSON(requestParameters.frontApplicationInputInputUserLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserValidate(requestParameters: PostAppUserValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserValidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postAppUserValidate1Raw(requestParameters: PostAppUserValidate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationUserLoggedModel>> {
        if (requestParameters.frontApplicationInputInputUserLoginModel === null || requestParameters.frontApplicationInputInputUserLoginModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputInputUserLoginModel','Required parameter requestParameters.frontApplicationInputInputUserLoginModel was null or undefined when calling postAppUserValidate1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/validate.json`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputInputUserLoginModelToJSON(requestParameters.frontApplicationInputInputUserLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationUserLoggedModelFromJSON(jsonValue));
    }

    /**
     */
    async postAppUserValidate1(requestParameters: PostAppUserValidate1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationUserLoggedModel> {
        const response = await this.postAppUserValidate1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postOldradioPingRadiobossRaw(requestParameters: PostOldradioPingRadiobossRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.artist !== undefined) {
            formParams.append('artist', requestParameters.artist as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.cover !== undefined) {
            formParams.append('cover', requestParameters.cover as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        const response = await this.request({
            path: `/add-to-playlist/radioboss`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postOldradioPingRadioboss(requestParameters: PostOldradioPingRadiobossRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postOldradioPingRadiobossRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postOldradioPingSeejayRaw(requestParameters: PostOldradioPingSeejayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.artist !== undefined) {
            queryParameters['artist'] = requestParameters.artist;
        }

        if (requestParameters.song !== undefined) {
            queryParameters['song'] = requestParameters.song;
        }

        if (requestParameters.onlyrefresh !== undefined) {
            queryParameters['onlyrefresh'] = requestParameters.onlyrefresh;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/add-to-playlist/seejay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postOldradioPingSeejay(requestParameters: PostOldradioPingSeejayRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postOldradioPingSeejayRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postOlduserGdprRaw(requestParameters: PostOlduserGdprRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontApplicationSuccessModel>> {
        if (requestParameters.frontApplicationInputGdprModel === null || requestParameters.frontApplicationInputGdprModel === undefined) {
            throw new runtime.RequiredError('frontApplicationInputGdprModel','Required parameter requestParameters.frontApplicationInputGdprModel was null or undefined when calling postOlduserGdpr.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/gdpr`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrontApplicationInputGdprModelToJSON(requestParameters.frontApplicationInputGdprModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontApplicationSuccessModelFromJSON(jsonValue));
    }

    /**
     */
    async postOlduserGdpr(requestParameters: PostOlduserGdprRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontApplicationSuccessModel> {
        const response = await this.postOlduserGdprRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putApiUserConnectFacebookRaw(requestParameters: PutApiUserConnectFacebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontWebTokenInputModel === null || requestParameters.frontWebTokenInputModel === undefined) {
            throw new runtime.RequiredError('frontWebTokenInputModel','Required parameter requestParameters.frontWebTokenInputModel was null or undefined when calling putApiUserConnectFacebook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/connectFacebook`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebTokenInputModelToJSON(requestParameters.frontWebTokenInputModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putApiUserConnectFacebook(requestParameters: PutApiUserConnectFacebookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putApiUserConnectFacebookRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putApiUserConnectGoogleRaw(requestParameters: PutApiUserConnectGoogleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontWebTokenInputModel === null || requestParameters.frontWebTokenInputModel === undefined) {
            throw new runtime.RequiredError('frontWebTokenInputModel','Required parameter requestParameters.frontWebTokenInputModel was null or undefined when calling putApiUserConnectGoogle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/connectGoogle`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebTokenInputModelToJSON(requestParameters.frontWebTokenInputModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putApiUserConnectGoogle(requestParameters: PutApiUserConnectGoogleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putApiUserConnectGoogleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putApiUserConnectMsRaw(requestParameters: PutApiUserConnectMsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontWebUserLoginModel>> {
        if (requestParameters.frontWebMsConnectExtendServiceToUserModel === null || requestParameters.frontWebMsConnectExtendServiceToUserModel === undefined) {
            throw new runtime.RequiredError('frontWebMsConnectExtendServiceToUserModel','Required parameter requestParameters.frontWebMsConnectExtendServiceToUserModel was null or undefined when calling putApiUserConnectMs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/connectMs`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebMsConnectExtendServiceToUserModelToJSON(requestParameters.frontWebMsConnectExtendServiceToUserModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontWebUserLoginModelFromJSON(jsonValue));
    }

    /**
     */
    async putApiUserConnectMs(requestParameters: PutApiUserConnectMsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontWebUserLoginModel> {
        const response = await this.putApiUserConnectMsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putApiUserEditRaw(requestParameters: PutApiUserEditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontWebEditUserModel === null || requestParameters.frontWebEditUserModel === undefined) {
            throw new runtime.RequiredError('frontWebEditUserModel','Required parameter requestParameters.frontWebEditUserModel was null or undefined when calling putApiUserEdit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebEditUserModelToJSON(requestParameters.frontWebEditUserModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putApiUserEdit(requestParameters: PutApiUserEditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putApiUserEditRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putApiUserRefreshRaw(requestParameters: PutApiUserRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontWebRefreshModel === null || requestParameters.frontWebRefreshModel === undefined) {
            throw new runtime.RequiredError('frontWebRefreshModel','Required parameter requestParameters.frontWebRefreshModel was null or undefined when calling putApiUserRefresh.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/user/refresh`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FrontWebRefreshModelToJSON(requestParameters.frontWebRefreshModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putApiUserRefresh(requestParameters: PutApiUserRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putApiUserRefreshRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetApiPodcastEpisodesOrderByEnum = {
    PubDate: 'PubDate',
    Listened: 'Listened'
} as const;
export type GetApiPodcastEpisodesOrderByEnum = typeof GetApiPodcastEpisodesOrderByEnum[keyof typeof GetApiPodcastEpisodesOrderByEnum];
/**
 * @export
 */
export const GetApiSearchEpisodeOrderByEnum = {
    PubDate: 'PubDate',
    Listened: 'Listened'
} as const;
export type GetApiSearchEpisodeOrderByEnum = typeof GetApiSearchEpisodeOrderByEnum[keyof typeof GetApiSearchEpisodeOrderByEnum];
/**
 * @export
 */
export const PostAppUserExternalLoginTypeEnum = {
    Facebook: 'facebook',
    Google: 'google',
    Apple: 'apple'
} as const;
export type PostAppUserExternalLoginTypeEnum = typeof PostAppUserExternalLoginTypeEnum[keyof typeof PostAppUserExternalLoginTypeEnum];
/**
 * @export
 */
export const PostAppUserExternalLogin1TypeEnum = {
    Facebook: 'facebook',
    Google: 'google',
    Apple: 'apple'
} as const;
export type PostAppUserExternalLogin1TypeEnum = typeof PostAppUserExternalLogin1TypeEnum[keyof typeof PostAppUserExternalLogin1TypeEnum];
