import {configureStore} from '@reduxjs/toolkit'

import searchReducer from "./search";
import rowlistReducer from "./rowlist";
import currentUserReducer from "./currentUser";
import radioReducer from "./playedItem";
import PlayerReducer from "../Player/state"
import createSagaMiddleware from "redux-saga";
import {IdAction} from "../Player/state/id";


export const createStore = () => {
    const middleware = createSagaMiddleware();
    const store = configureStore({
        // @ts-ignore
        reducer: {
            search: searchReducer,
            listrow:  rowlistReducer,
            playedItem: radioReducer,
            currentUser: currentUserReducer,
            ...PlayerReducer,

        },
        middleware: [
            middleware
        ],
    });

    import("../Player/saga").then(r => {
        middleware.run(r.Saga);
    })



    store.dispatch<IdAction>({
        type: "ID",
        payload: 1
    })

    return store;
}



let tmpStore = createStore();

export type RootState = ReturnType<typeof tmpStore.getState>;

export type AppDispatch = typeof tmpStore.dispatch;

// @ts-ignore
tmpStore = null;




