"use client";

import {
    createContext,
    ForwardedRef,
    forwardRef,
    useContext,
    useEffect,
    useMemo, useRef, useState,
} from "react";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";
import {ApiClient} from "../../generated/ApiClient";

const Ctx = createContext<{
    add: (id: string) => void,
    remove: (id: string) => void,

}>({

    add: () =>{
        return () => {}
    },
    remove: () => {},
});


export const AdvertisementProvider = (props: {
    children: React.ReactNode
}) => {
    const loc = useLocation();

    const firstPage = useRef<string[]>([loc.hash, loc.pathname, loc.search]);

    useEffect(() => {
        const r = firstPage.current;
        if (r.length && (r[0] !== loc.hash || r[1] !== loc.pathname || r[2] !== loc.search))
        {
            firstPage.current = [];
        }
    }, [loc.hash, loc.pathname, loc.search]);

    const o = useMemo(()=> {
        let state: Array<string> = [];
        let lastCall = 0;
        let hasTimeout = setTimeout (() =>{});
        const minTime = 10000;
        let iframes:any[] = [];

        const sendDivs = () => {
            const newIframe:any[] = [];
            const send = state.map(x => ({
                position: x,
                block: false,
                used: false,
                width: window.innerWidth || 0
            })).map(x => ({
                ...x,
                used: Array.from(document.getElementById(x.position)?.getElementsByTagName("iframe") ?? [])?.[0]
            })).map(x => {
                if (x.used)
                    newIframe.push(x.used);
                return ({
                    ...x,
                    used: x.used && iframes.filter(y => y === x.used).length === 0
                });
            });

            console.log(send);

            iframes = newIframe;

            new ApiClient().postApiStatisticsPositions({
                frontWebStatisticsPositionsModel: {
                    positions: send,
                }
            }, {
                 credentials: "omit"
            });
        }

        const refresh = debounce(()=> {

            if (typeof window !== "undefined") {
                const d = new Date().getTime();
                if (!lastCall || lastCall + minTime < d) {
                    let  meziCall = "max";
                    if (lastCall)
                        meziCall = `${(new Date().getTime() - lastCall)/1000}`;
                    lastCall = new Date().getTime();

                    console.warn(`Pokus o aktualizaci ${state.join(',')}, čas mezi voláním: ${meziCall}`)
                    // @ts-ignore
                    if (typeof window["roleadingByDivIds"] !== 'undefined') {
                        const exits = state.filter(x => !!document.getElementById(x)).join(",");
                        if (!firstPage.current.length) {
                            // console.log("reloading");
                            // @ts-ignore - adformMobilniBranding se nerefreshuje
                            window.roleadingByDivIds(exits);
                        }
                        setTimeout(sendDivs, 4000);
                    }
                    else {
                        console.warn(`not function roleadingByDivIds`);
                        setTimeout(sendDivs, 4000);
                    }
                } else {
                    clearTimeout(hasTimeout);
                    hasTimeout = setTimeout(refresh, lastCall  + minTime - d);
                }
            }
        }, 200);

        return {
            add: (id: string) => {
                if (state.indexOf(id) > -1)
                    console.warn(`same id ${id}`)
                state.push(id);
                refresh();
            },
            remove: (id:string) => {
                state = state.filter(x => x !== id)

                refresh();
            },
        }
    }, [])

    return <Ctx.Provider value={o}>
        {props.children}
    </Ctx.Provider>
}



const AdvertisementInner = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { id: string, visible?: boolean }, ref:ForwardedRef<HTMLDivElement>) => {
    const data = useContext(Ctx);
    const pathname = useLocation().pathname;

    useEffect(() => {
        data.add(props.id);
        return () => data.remove(props.id);
    }, [pathname, props.id, data]);

    if (props.visible === undefined || props.visible)
        return <div ref={ref} {...props} />;

    return null;
}

export const Advertisement = forwardRef(AdvertisementInner);