import React, {createContext, useContext, useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";

const DomainContext = createContext<string>("");

const GoogleTag  = () => {

    const { pathname, search } = useLocation();
    const init = useRef(true);

    useEffect(() => {
        if (init.current)
        {
            init.current = false;
            return;
        }

        try {
            // @ts-ignore
            gemius_event('_view_', pp_gemius_identifier_spa, '');
            // @ts-ignore
            console.log('gemius_event', "_view_", pp_gemius_identifier_spa, 'posilam prazdny retezec');
        }
        catch (e) {

        }

    }, [pathname, search]);

    useEffect(() =>  {
        try {
            // @ts-ignore
            gtag('_trackPageview');
            console.log("gtag", '_trackPageview')
        }
        catch(e) {
          //  console.error("gtag")
        }

        try {
            // @ts-ignore
            fbq('track', 'PageView');
            console.log("fbq", 'track', "PageView")
        }
        catch(e) {
         //   console.error("gtag")
        }

    }, [pathname, search]);

    return null;
}
export const DomainProvider = ({ children, url } : { children: React.ReactNode, url: string}) => {
    const cUrl = new URL(url);

    return <DomainContext.Provider value={`${cUrl.protocol}//${cUrl.host}`}>
        <GoogleTag />
        {children}
    </DomainContext.Provider>
}

export const useDomain = () => useContext(DomainContext)



