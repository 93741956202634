/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontWebRadioModel
 */
export interface FrontWebRadioModel {
    /**
     * Id
     * @type {number}
     * @memberof FrontWebRadioModel
     */
    id?: number;
    /**
     * Slug
     * @type {string}
     * @memberof FrontWebRadioModel
     */
    slug: string;
    /**
     * Název rádia
     * @type {string}
     * @memberof FrontWebRadioModel
     */
    name: string;
    /**
     * Slogan
     * @type {string}
     * @memberof FrontWebRadioModel
     */
    slogan?: string;
    /**
     * Logo rádia
     * @type {string}
     * @memberof FrontWebRadioModel
     */
    logo: string;
    /**
     * Logo rádia (og:)
     * @type {string}
     * @memberof FrontWebRadioModel
     */
    ogLogo?: string;
    /**
     * Popis radia (og:)
     * @type {string}
     * @memberof FrontWebRadioModel
     */
    metaDescription?: string;
    /**
     * Stránky rádia
     * @type {string}
     * @memberof FrontWebRadioModel
     */
    url?: string;
}

/**
 * Check if a given object implements the FrontWebRadioModel interface.
 */
export function instanceOfFrontWebRadioModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "logo" in value;

    return isInstance;
}

export function FrontWebRadioModelFromJSON(json: any): FrontWebRadioModel {
    return FrontWebRadioModelFromJSONTyped(json, false);
}

export function FrontWebRadioModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebRadioModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'slogan': !exists(json, 'slogan') ? undefined : json['slogan'],
        'logo': json['logo'],
        'ogLogo': !exists(json, 'ogLogo') ? undefined : json['ogLogo'],
        'metaDescription': !exists(json, 'metaDescription') ? undefined : json['metaDescription'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function FrontWebRadioModelToJSON(value?: FrontWebRadioModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'slogan': value.slogan,
        'logo': value.logo,
        'ogLogo': value.ogLogo,
        'metaDescription': value.metaDescription,
        'url': value.url,
    };
}

