import {uri, UriParams} from "url-routes-generator";


const Routes = {
    "seznam_radii_nejposlouchanejsi": (items: UriParams = {}) => uri("/radia")(items),
    "seznam_radii_abecedne": (items: UriParams = {}) => uri("/radia/abecedne")(items),
    "seznam_radii_styl": (items: UriParams = {}) => uri("/radia/styl")(items),
    "seznam_radii_styl_[styl]": (styl: string, items: UriParams = {}) => uri("/radia/styl/:styl", {
        styl
    })(items),
    "seznam_radii_moje": () => uri("/radia/moje")(),
    "seznam_podcastu": (items: UriParams = {}) => uri("/podcasty")(items),
    "seznam_podcastu_[category]": (category: string, items: UriParams = {}) => uri("/podcasty/styl/:category", { category })(items),
    "search" : (q?:string, from?: number, only?: string, orderBy?: string ) => {
        if (q) {
            const optionals: {[path:string]: string} = {
                q,
            }
            if (from) {
                optionals.from = `${from}`;
            }
            if (orderBy && only === "epizody")
                optionals.orderBy = `${orderBy}`;

            if (only) {
                return uri(`/hledani/:only`, { only })(optionals);
            }
            return uri('/hledani')(optionals);

        }
        return uri("/hledani")()
    },
    "radio_homepage": (slug: string, items: UriParams = {}, hash: undefined|string = undefined) => uri("/radia/:slug", { slug})(items) + (hash ? `#${hash}` : ""),
    "radio_playlist": (slug: string, items: UriParams = {}, hash: undefined|string = undefined) => uri("/radia/:slug/playlist", { slug})(items) + (hash ? `#${hash}` : ""),
    "radio_info": (slug: string, items: UriParams = {}, hash: undefined|string = undefined) => uri("/radia/:slug/info", { slug})(items) + (hash ? `#${hash}` : ""),
    "radio_podobnaradia": (slug: string, items: UriParams = {}, hash: undefined|string = undefined) => uri("/radia/:slug/podobna-radia", { slug})(items) + (hash ? `#${hash}` : ""),
    "radio_program": (slug: string, items: UriParams = {}, hash: undefined|string = undefined) => uri("/radia/:slug/podcasty", { slug})(items) + (hash ? `#${hash}` : ""),
    "radio_songprogram": (slug: string, items: UriParams = {}, hash: undefined|string = undefined) => uri("/radia/:slug/program", { slug})(items) + (hash ? `#${hash}` : ""),
    "radio_program_detail": (slug: string, slugPodcast: string, orderBy: string|undefined = undefined, items: UriParams = {}, hash: undefined|string = undefined) => {
        const url = (() => {
            if (orderBy && orderBy !== "PubDate")
                return uri("/radia/:slug/podcasty/:slugPodcast", { slug, slugPodcast})({...items, orderBy });
            return uri("/radia/:slug/podcasty/:slugPodcast", { slug, slugPodcast})({...items });
        })();
        return url + (hash ? `#${hash}` : "")
    },
    "radio_program_detail_episode": (slug: string, slugPodcast: string, id: string, name: string, items: UriParams = {}, hash: undefined|string = undefined)=> {
        return uri("/radia/:slug/podcasty/:slugPodcast/:id-:name", { slug, slugPodcast, id, name})( items) + (hash ? `#${hash}` : "");
    },
    "text_jak_si_nas_naladite": (where: string) => {
        return uri(`/jak-nas-naladite#${where}`)();
    },
    "program_detail": (slugPodcast: string, orderBy: string|undefined = undefined, items: UriParams = {}, hash: undefined|string = undefined) => {
        const url = (() => {
            if (orderBy && orderBy !== "PubDate")
                return uri("/podcasty/:slugPodcast", { slugPodcast})({...items, orderBy });
            return uri("/podcasty/:slugPodcast", { slugPodcast})({...items });
        })();
        return url + (hash ? `#${hash}` : "")
    },
    "program_detail_episode": (slugPodcast: string, id: string, name: string, items: UriParams = {}, hash: undefined|string = undefined)=> {
        return uri("/podcasty/:slugPodcast/:id-:name", { slugPodcast, id, name})( items) + (hash ? `#${hash}` : "");
    }

}

export default Routes;