/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontApplicationUserModel
 */
export interface FrontApplicationUserModel {
    /**
     * Mail
     * @type {string}
     * @memberof FrontApplicationUserModel
     */
    email?: string;
    /**
     * Facebook id
     * @type {string}
     * @memberof FrontApplicationUserModel
     */
    facebookId?: string;
    /**
     * Google id
     * @type {string}
     * @memberof FrontApplicationUserModel
     */
    googleId?: string;
    /**
     * Apple id
     * @type {string}
     * @memberof FrontApplicationUserModel
     */
    appleId?: string;
    /**
     * ID rádií
     * @type {Array<number>}
     * @memberof FrontApplicationUserModel
     */
    favourites: Array<number>;
    /**
     * Apple id
     * @type {number}
     * @memberof FrontApplicationUserModel
     */
    id: number;
    /**
     * Souhlas s gdpr
     * @type {boolean}
     * @memberof FrontApplicationUserModel
     */
    gdpr?: boolean;
}

/**
 * Check if a given object implements the FrontApplicationUserModel interface.
 */
export function instanceOfFrontApplicationUserModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "favourites" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function FrontApplicationUserModelFromJSON(json: any): FrontApplicationUserModel {
    return FrontApplicationUserModelFromJSONTyped(json, false);
}

export function FrontApplicationUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontApplicationUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'facebookId': !exists(json, 'facebook_id') ? undefined : json['facebook_id'],
        'googleId': !exists(json, 'google_id') ? undefined : json['google_id'],
        'appleId': !exists(json, 'apple_id') ? undefined : json['apple_id'],
        'favourites': json['favourites'],
        'id': json['id'],
        'gdpr': !exists(json, 'gdpr') ? undefined : json['gdpr'],
    };
}

export function FrontApplicationUserModelToJSON(value?: FrontApplicationUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'facebook_id': value.facebookId,
        'google_id': value.googleId,
        'apple_id': value.appleId,
        'favourites': value.favourites,
        'id': value.id,
        'gdpr': value.gdpr,
    };
}

