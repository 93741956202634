/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontApplicationInputOAuthDataModel } from './FrontApplicationInputOAuthDataModel';
import {
    FrontApplicationInputOAuthDataModelFromJSON,
    FrontApplicationInputOAuthDataModelFromJSONTyped,
    FrontApplicationInputOAuthDataModelToJSON,
} from './FrontApplicationInputOAuthDataModel';

/**
 * 
 * @export
 * @interface FrontApplicationInputOAuthLoginModel
 */
export interface FrontApplicationInputOAuthLoginModel {
    /**
     * 
     * @type {FrontApplicationInputOAuthDataModel}
     * @memberof FrontApplicationInputOAuthLoginModel
     */
    oauthData: FrontApplicationInputOAuthDataModel;
    /**
     * Souhlas s gdpr
     * @type {boolean}
     * @memberof FrontApplicationInputOAuthLoginModel
     */
    gdprAgree?: boolean;
    /**
     * Client
     * @type {string}
     * @memberof FrontApplicationInputOAuthLoginModel
     */
    clientId: string;
    /**
     * Secret
     * @type {string}
     * @memberof FrontApplicationInputOAuthLoginModel
     */
    clientSecret: string;
}

/**
 * Check if a given object implements the FrontApplicationInputOAuthLoginModel interface.
 */
export function instanceOfFrontApplicationInputOAuthLoginModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "oauthData" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "clientSecret" in value;

    return isInstance;
}

export function FrontApplicationInputOAuthLoginModelFromJSON(json: any): FrontApplicationInputOAuthLoginModel {
    return FrontApplicationInputOAuthLoginModelFromJSONTyped(json, false);
}

export function FrontApplicationInputOAuthLoginModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontApplicationInputOAuthLoginModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oauthData': FrontApplicationInputOAuthDataModelFromJSON(json['oauth_data']),
        'gdprAgree': !exists(json, 'gdprAgree') ? undefined : json['gdprAgree'],
        'clientId': json['client_id'],
        'clientSecret': json['client_secret'],
    };
}

export function FrontApplicationInputOAuthLoginModelToJSON(value?: FrontApplicationInputOAuthLoginModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oauth_data': FrontApplicationInputOAuthDataModelToJSON(value.oauthData),
        'gdprAgree': value.gdprAgree,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
    };
}

