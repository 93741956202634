/* tslint:disable */
/* eslint-disable */
/**
 * radia.cz
 * Seznam endpointu
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrontWebRadioModel } from './FrontWebRadioModel';
import {
    FrontWebRadioModelFromJSON,
    FrontWebRadioModelFromJSONTyped,
    FrontWebRadioModelToJSON,
} from './FrontWebRadioModel';

/**
 * 
 * @export
 * @interface FrontWebSongRadioModel
 */
export interface FrontWebSongRadioModel {
    /**
     * 
     * @type {FrontWebRadioModel}
     * @memberof FrontWebSongRadioModel
     */
    radio: FrontWebRadioModel;
    /**
     * 
     * @type {number}
     * @memberof FrontWebSongRadioModel
     */
    id: number;
    /**
     * Interpret
     * @type {string}
     * @memberof FrontWebSongRadioModel
     */
    interpret?: string;
    /**
     * Název skladby
     * @type {string}
     * @memberof FrontWebSongRadioModel
     */
    song?: string;
    /**
     * Začátek skladby
     * @type {string}
     * @memberof FrontWebSongRadioModel
     */
    beginAt: string;
    /**
     * Začátek skladby
     * @type {string}
     * @memberof FrontWebSongRadioModel
     */
    endAt?: string;
    /**
     * Obrázek
     * @type {string}
     * @memberof FrontWebSongRadioModel
     */
    image?: string;
    /**
     * Aktivní
     * @type {boolean}
     * @memberof FrontWebSongRadioModel
     */
    active?: boolean;
    /**
     * Má text (slug radia pro ziskani textu)
     * @type {string}
     * @memberof FrontWebSongRadioModel
     */
    lyricsRadioSlug?: string;
}

/**
 * Check if a given object implements the FrontWebSongRadioModel interface.
 */
export function instanceOfFrontWebSongRadioModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "radio" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "beginAt" in value;

    return isInstance;
}

export function FrontWebSongRadioModelFromJSON(json: any): FrontWebSongRadioModel {
    return FrontWebSongRadioModelFromJSONTyped(json, false);
}

export function FrontWebSongRadioModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontWebSongRadioModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'radio': FrontWebRadioModelFromJSON(json['radio']),
        'id': json['id'],
        'interpret': !exists(json, 'interpret') ? undefined : json['interpret'],
        'song': !exists(json, 'song') ? undefined : json['song'],
        'beginAt': json['beginAt'],
        'endAt': !exists(json, 'endAt') ? undefined : json['endAt'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'lyricsRadioSlug': !exists(json, 'lyricsRadioSlug') ? undefined : json['lyricsRadioSlug'],
    };
}

export function FrontWebSongRadioModelToJSON(value?: FrontWebSongRadioModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'radio': FrontWebRadioModelToJSON(value.radio),
        'id': value.id,
        'interpret': value.interpret,
        'song': value.song,
        'beginAt': value.beginAt,
        'endAt': value.endAt,
        'image': value.image,
        'active': value.active,
        'lyricsRadioSlug': value.lyricsRadioSlug,
    };
}

