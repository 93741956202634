import {AdvertisementProvider} from "../components/Advertisement/Advertisement";
import {ProgressProvider} from "../components/Player/progress";
import {MessageProvider} from "../contexts/useMessage";
import {LoadbarProvider} from "../contexts/useLoader";
import {AdformLeaderboard} from "../components/Advertisement/AdformLeaderboard";
import {PageHeader} from "../components/PageHeader";
import {Footer} from "../components/Footer/Footer";
import {BottomViewPlayer} from "../components/Player/view/Bottom";
import {AdformMobilni} from "../components/Advertisement/AdformMobilni";
import {Player} from "../components/Player";
import {DomainProvider} from "../contexts/useDomain";
import {startTransition, useEffect, useState} from "react";
import {createStore} from "../components/Store";
import {Provider} from "react-redux";
import {Header} from "../components/Header";
import {PodcastTimes} from "../contexts/usePodcastTimes";
import {SafariProvider} from "../contexts/useIsSafari";
import {LyricsProvider} from "../contexts/useLyrics";

const DivFb = () => {

    const [s, setS] = useState(false);
    useEffect(() => {
        startTransition(() => setS(true));
    }, []);
    return s ? <div id={"fb-root"} suppressHydrationWarning/> : <div id={"fb-root-prepare"} suppressHydrationWarning/>;
}

const Layout = (props: {
    children: React.ReactNode,
    domain: string
}) => {
    const [store] = useState(() => createStore());


    return <Provider store={store}>
        <SafariProvider>
            <LoadbarProvider>
                <LyricsProvider>
                <PodcastTimes>
                <DomainProvider url={props.domain}>
                    <AdvertisementProvider>
                        <ProgressProvider>
                            <MessageProvider>
                                <DivFb />
                                <AdformLeaderboard/>
                                <div className="container-page">
                                    <PageHeader />
                                    <header>
                                        <Header/>
                                    </header>
                                    {props.children}
                                    <Footer/>
                                </div>
                                <BottomViewPlayer/>
                                <AdformMobilni/>
                                <Player/>
                                <div id="rczplayer"/>
                            </MessageProvider>
                        </ProgressProvider>
                    </AdvertisementProvider>
                </DomainProvider>
                </PodcastTimes>
                </LyricsProvider>
            </LoadbarProvider>
        </SafariProvider>
    </Provider>
}

export default Layout;